export const ExperienceList = [
  {
    date: "April 2023 - Present",
    iconBackground: "#f9004d",
    title: "Life Is The Games",
    subtitle: "Unity Developer",
    responsibilities: [
      "Manekin Arena",
      "Foresight Sports - FSX Pro",
      "Foresight - Calibration App",
    ],
    icon: "WorkIcon",
  },
  {
    date: "October 2021 - December 2022",
    iconBackground: "#f9004d",
    title: "Optic Power | Code Power",
    subtitle: "Unreal | Unity Developer",
    responsibilities: ["Stardust.gg", "Arctic7 - TDB"],
    icon: "WorkIcon",
  },
  {
    date: "May 2021 - August 2021",
    iconBackground: "#f9004d",
    title: "Nediar",
    subtitle: "AR Unity Developer",
    responsibilities: ["AR Hololens App"],
    icon: "WorkIcon",
  },
  {
    date: "2019 March - October 2021",
    iconBackground: "#f9004d",
    title: "Developer at Dream House Studios",
    subtitle: "Unreal | Unity Developer",
    responsibilities: ["Seguridad Vial"],
    icon: "WorkIcon",
  },
  {
    date: "2014 - 2021",
    iconBackground: "#191919",
    title: "Engineering In Digital Entertainment Design",
    subtitle: "Universidad Pontificia Bolivariana | UPB",
    responsibilities: ["Medellín - Antioquia"],
    icon: "SchoolIcon",
  },
  {
    date: "March 2018 - 2019",
    iconBackground: "#f9004d",
    title: "Developer at Dream House Studios",
    subtitle: "Unity Developer",
    responsibilities: ["Sofasa Logistica", "Seguridad Vial"],
    icon: "WorkIcon",
  },
  {
    date: "2019",
    iconBackground: "#191919",
    title: "English, THRESHOLD",
    subtitle: "Universidad Pontificia Bolivariana | UPB",
    responsibilities: ["Medellín - Antioquia"],
    icon: "SchoolIcon",
  },
  {
    date: "2019",
    iconBackground: "#191919",
    title: "English, Upper-Intermediate.",
    subtitle: "Oxford English Institute",
    responsibilities: ["Ibagué - Tolima"],
    icon: "SchoolIcon",
  },
  {
    date: "2009",
    iconBackground: "#191919",
    title: "Technical In Computer Maintenance",
    subtitle: "Redecomputo",
    responsibilities: ["Ibagué - Tolima"],
    icon: "SchoolIcon",
  },
  {
    date: "2009",
    iconBackground: "#191919",
    title: "Technical Programmer",
    subtitle: "Redecomputo",
    responsibilities: ["Ibagué - Tolima"],
    icon: "SchoolIcon",
  },
];
